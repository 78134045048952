body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  box-sizing: border-box;
  font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
  hyphens: auto;
  line-height: 1.65;
  margin: 0 auto;
  max-width: 572px;
  min-height: 100vh;
  padding: 34px;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.37);
}
h1 {
  font-size: 45px;
}
h2 {
  margin-top: 1.5em;
}
p {
  font-size: 13px;
}
a {
  border-bottom: 1px solid white;
  color: #0071ce;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}
code,
pre {
  color: #d400ff;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 0.92em;
}
code:before,
code:after {
  content: '\`';
}
.bg {
  background-color: #d7d8d9;
  padding-bottom: 80px;
}
.header {
  height: 270px;
  background-color: #23408f;
  margin-bottom: -115px;
}

@media only screen and (max-width: 740px) {
  main {
    margin: 0 20px;
    padding: 30px;
  }
}

.logos {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  img {
    margin-right: 30px;
  }
}