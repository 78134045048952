.vault-button {
  border-radius: 2px;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
  line-height: 17px; 
  align-items: center;
  width: 200px;
  height: 50px;
  cursor: pointer;
  background-color: #0071ce;
  border: 1px solid #006dba;
  color: #fff;    
  text-transform: uppercase;
  &:hover, &:focus {
    color: #fff;
    background-color: lighten(#0071ce, 5%);
    border-color: lighten(#006dba, 5%);
  }
  &:disabled {
    background-color: desaturate(#0071ce, 30%);
    cursor: default;
  }
}