.vault-input {
  width: 70%;
  input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    height: 42px;
    width: 100%;
    font-size: 16px;
    margin: 0 0 8px 0;
    outline: none;
    transition: box-shadow .3s, border .3s;
    &:focus {
      border-bottom: 1px solid #0071ce;
      box-shadow: 0 1px 0 0 #0071ce;
    }
    &::placeholder {
      color: #9e9e9e;
      font-size: 13px;
    }
  }
}
.vault-select {
  width: 70%;
  .vault {
    &__control {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #9e9e9e;
      border-radius: 0;
      height: 42px;
      transition: box-shadow .3s, border .3s;
      &--is-focused {
        border: none;
        border-bottom: 1px solid #0071ce;
        box-shadow: 0 1px 0 0 #0071ce;
      }
    }
    &__value-container {
      padding: 0;
    }
    &__placeholder {
      color: #9e9e9e;
      font-size: 13px;
    }
    &__indicator {
      color: #9e9e9e;
      &-separator {
        background-color: #9e9e9e;
      }
    }
  }
}
.vault-label {
  margin: 0;
  color: #868686;
  font-size: 16px;
  transition: color .3s;
  &.active {
    color: #0071ce;
  }
}

@media only screen and (max-width: 640px) {
  .vault-input, .vault-select {
    width: 100%;
  }
}

.input-form {
  padding: 20px 0;
}

span.error {
  font-size: 12px;
  color: #dc3545;
}